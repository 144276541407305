import defaultStyles from './registration-step1.module.scss'
import ButtonThemes from 'components/parts/button/themes'

interface IRegistrationStep1 {
  isButtonHidden?: boolean
  isStudentsReg?: boolean
  isLoginHidden?: boolean
  setProgressStep?: (value: any, errors: any) => void
  customStyles?: { [key: string]: string }
  buttonTheme?: ButtonThemes
  consentModificator?: string
  handleLoginButton?: () => void
  onSubmitEnd?: () => void
  btnText?: string
}

const InputPCStyles = {
  inputNameModificator: defaultStyles.nameRowStyle,
}

const passwordProps = {
  placeholder: 'Придумайте пароль',
  inputNameModificator: defaultStyles.nameRowStyle,
}

const customSelectStyle = {
  container: (provided: any) => ({
    ...provided,
    position: 'absolute',
    top: 'auto',
    marginTop: '10px',
    width: '100%',
    backgroundColor: '#FFFFFF',
    padding: '16px',
    border: '1px solid #EBEBEB',
    borderRadius: '10px',
    zIndex: '2',
  }),
  menuList: (provided: any) => ({
    ...provided,
    paddingBottom: 0,
    paddingTop: 0,
    marginRight: '8px',
    overflow: 'hidden',
    overflowY: 'hidden',
    display: 'flex',
    height: '200px',
    zIndex: '2',
  }),
}
const InputSpecialitiesStyles = {
  inputNameModificator: defaultStyles.nameRowStyle,
  inputWrapperModificator: defaultStyles.inputWrapperStyle,
  customSelectStyle: customSelectStyle,
  modificator: defaultStyles.inputStyle,
}

export { InputPCStyles, InputSpecialitiesStyles, passwordProps }
export type { IRegistrationStep1 }

const selectStyles = {
  container: (provided: any) => ({
    ...provided,
    position: 'absolute',
    top: 'auto',
    marginTop: '10px',
    width: '357px',
    backgroundColor: '#FFFFFF',
    padding: '16px',
    border: '1px solid #EBEBEB',
    borderRadius: '10px',
    zIndex: '2',
  }),
  option: (provided: any) => ({
    ...provided,
    padding: '10px 12px',
    backgroundColor: 'transparent',
    color: 'black',
  }),
  menu: () => ({
    padding: '5px 0',
    borderRadius: '16px',
  }),
  menuList: (provided: any) => ({
    ...provided,
    paddingBottom: 0,
    paddingTop: 0,
    marginRight: '8px',
    overflow: 'hidden',
    overflowY: 'hidden',
    display: 'flex',
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    borderRadius: '10px',
    padding: '5px',
    paddingLeft: '28px',
    maxHeight: '46px',
    boxShadow: 'none',
    borderColor: state.isFocused ? '#19b5ff' : '#BCBCC2',
    '&:hover': {
      borderColor: '#19b5ff',
      backgroundColor: '#ffffff',
    },
    backgroundColor: '#ffffff',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: (provided: any) => ({
    ...provided,
    pointerEvents: 'none',
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    pointerEvents: 'all',
    transition: 'all .5s',
  }),
  multiValue: (provided: any) => ({
    ...provided,
    backgroundColor: '#ffffff',
    fontSize: '18px',
    maxWidth: '95%',
  }),
  multiValueRemove: (provided: any) => ({
    ...provided,
    color: '#19b5ff',
    '&:hover': {
      backgroundColor: '#ffffff',
      color: '#000000',
    },
  }),
}

export { selectStyles }

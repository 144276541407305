import { FC, useState } from 'react'
import clsx from 'clsx'

import AuthPopupWrapper from '../auth-popup-wrapper/auth-popup-wrapper'

import { SessionStorageKeys } from 'constants/storage'

import styles from './recover-success.module.scss'

const steps = [
  `Перейдите по ссылке и введите свой email&ensp;
    <a 
      href='https://sendsay.ru/form/x_1628593677368629/2/' 
      target="_blank"
      rel="noreferrer"
      class=${styles.link}
    >
        https://sendsay.ru/form/x_1628593677368629/2/
    </a>`,
  'Вам придет письмо подтверждение (проверьте папку спам)',
  'Зайдите в спам и отметьте, что наше письмо не спам, нажмите кнопку подтвердить в письме',
]

const RecoverSuccess: FC = () => {
  const [isFull, setFull] = useState(false)
  const email = sessionStorage.getItem(SessionStorageKeys.PASSWORD_RECOVER)
  return (
    <div className={styles.wrapper}>
      <AuthPopupWrapper title="Восстановление пароля">
        <div className={styles.passwordRecovery}>
          <div className={styles.mainContent}>
            <p>
              Мы отправили вам письмо для восстановления пароля на электронную почту{' '}
              <span className={styles.email}>{email} </span>
            </p>
          </div>
        </div>

        <div className={styles.instractionBlock}>
          <p
            onClick={() => setFull(true)}
            className={clsx(styles.instractionTitle, {
              [styles.fullTitle]: isFull,
            })}
          >
            Не пришло письмо?
          </p>
          {isFull && (
            <div>
              <p>
                Возможно, вы добавили нас в спам. Если письма в папке “спам” не оказалось,
                воспользуйтесь инструкцией:
              </p>
              <ul className={styles.instractionSteps}>
                {steps.map((step, key) => (
                  <li
                    key={key}
                    className={styles.instractionStep}
                    dangerouslySetInnerHTML={{
                      __html: `<span>${key + 1}</span>` + `<p>${step}</p>`,
                    }}
                  />
                ))}
              </ul>

              <p>
                Остались вопросы? Напишите нам на почту&ensp;
                <a href="mailto:info@medpoint.pro" className={styles.linkMedpoint}>
                  info@medpoint.pro
                </a>
              </p>
            </div>
          )}
        </div>
      </AuthPopupWrapper>
    </div>
  )
}

export default RecoverSuccess

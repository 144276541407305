import { capitalizeFirstLetter } from './captitalizeFirstLetter'
import { IOptions, IAnyObj, IObj } from 'constants/types/common.types'

const trimFields = (values: IAnyObj, specialFields: IObj<string[]>) =>
  Object.keys(values).reduce((acc, key) => {
    if (specialFields.noTrimed.includes(key)) {
      return { ...acc, [key]: values[key] }
    }

    if (specialFields.prepareIdsArr.includes(key)) {
      const Ids = values[key].map((el: IOptions) => el.value)
      return { ...acc, [key]: Ids }
    }

    let data = values[key]

    const stringedData = String(data)
    if (stringedData) {
      const trimmedData = stringedData.trim()
      if (specialFields.noCapitalize.includes(key)) data = trimmedData
      else if (specialFields.allCapitalized.includes(key)) {
        data = trimmedData
      } else data = capitalizeFirstLetter(trimmedData)
    }
    return { ...acc, [key]: data }
  }, {})

export { trimFields }

import { FC, useCallback, useState } from 'react'
import clsx from 'clsx'
import InputMask from 'react-input-mask'

import styles from './input-validation-react.module.scss'

const FieldsNeedToBeCapitalized = ['Фамилия', 'Имя', 'Отчество']
const FieldsNeedToBeLowercase = ['Email']
interface IValidationInput extends React.InputHTMLAttributes<HTMLInputElement> {
  nameInput: string
  name: string
  error?: any
  errorWithoutText?: boolean
  mask?: string
  touched?: boolean
  modificator?: string | boolean
  labelModificator?: string
  inputNameModificator?: string
  innerContainerModificator?: string
  inputWrapperModificator?: string
  errorModificator?: string
  dynamicName?: boolean
  noName?: boolean
  nameStar?: boolean
  setInputRef?: (element: HTMLInputElement) => void
  children?: React.ReactNode
}

const ValidationInput: FC<IValidationInput> = ({
  nameInput,
  name,
  nameStar,
  value,
  placeholder,
  error,
  errorWithoutText,
  mask,
  touched,
  modificator,
  labelModificator,
  inputNameModificator,
  innerContainerModificator,
  inputWrapperModificator,
  errorModificator,
  dynamicName,
  noName,
  children,
  setInputRef,
  onBlur,
  ...props
}) => {
  const [focusActive, setFocusActive] = useState(false)

  const measuredRef = useCallback(async (node) => {
    if (node) {
      setTimeout(() => {
        const element = node.getInputDOMNode()
        const isAutofilled = element
          ? getComputedStyle(element, null).getPropertyValue('appearance') === 'menulist-button'
          : false
        isAutofilled && setFocusActive(true)
      }, 800)
    }
  }, [])

  return (
    <div
      className={clsx(styles.innerContainer, {
        [innerContainerModificator as string]: innerContainerModificator,
      })}
    >
      <label
        className={clsx(styles.labelInput, {
          [labelModificator as string]: labelModificator,
          [styles.dynamicName]: dynamicName,
        })}
      >
        <p
          className={clsx(styles.outerName, {
            [styles.active]: dynamicName && (focusActive || value),
            [inputNameModificator as string]: inputNameModificator,
            [styles.disabled]: noName,
          })}
        >
          {nameStar ? `${nameInput}*` : nameInput}
        </p>

        <div
          className={clsx(styles.inputWrapper, {
            [inputWrapperModificator as string]: inputWrapperModificator,
          })}
        >
          <InputMask
            className={clsx(styles.input, {
              [styles.error]: error || errorWithoutText,
              [modificator as string]: modificator,
              [styles.capitalized]: FieldsNeedToBeCapitalized.includes(nameInput),
              [styles.tolowercase]: FieldsNeedToBeLowercase.includes(nameInput),
            })}
            {...props}
            value={value || ''}
            onFocus={() => {
              setFocusActive(true)
            }}
            onBlur={(e) => {
              onBlur && onBlur(e)
              setFocusActive(false)
            }}
            placeholder={dynamicName ? '' : placeholder}
            mask={mask || ''}
            inputRef={setInputRef}
            ref={dynamicName ? measuredRef : null}
          />
          {children}
        </div>
      </label>
      <div>
        <p
          className={clsx(styles.errorCheck, {
            [errorModificator as string]: errorModificator,
            [styles.active]: error?.message,
          })}
        >
          {error?.message}
        </p>
      </div>
    </div>
  )
}

export default ValidationInput

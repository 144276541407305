enum ButtonThemes {
  SECOND = 'second',
  THIRD = 'third',
  FOURTH = 'fourth',
  FIFTH = 'fifth',
  BLACK = 'black',
  BLACK_SECOND = 'black_second',
  GREY = 'grey',
  WHITE = 'white',
  PURPLE = 'purple',
  CONGRESS = 'congress',
  STUDENTS = 'students',
  STUDENTS_BLUE = 'studentsBlue',
  STUDENTS_SECONDARY = 'studentsSecondary',
  STUDENTS_NO_HOVER = 'studentsNoHover',
  WHITE_NO_HOVER = 'whiteNoHover',
  YELLOW = 'yellow',
}

const secondaryMods = [ButtonThemes.SECOND, ButtonThemes.THIRD]
const thirdMods = [ButtonThemes.THIRD, ButtonThemes.FOURTH]

export default ButtonThemes
export { secondaryMods, thirdMods }

import { FC } from 'react'
import Image from 'next/image'
import clsx from 'clsx'
import { components } from 'react-select'

import ScrollArea from 'containers/scroll-area/scroll-area'

import { STATIC_URL } from 'constants/data'

import styles from './select-multi.module.scss'

const searchIconPath = `${STATIC_URL}/filters/search-icon.svg`

/**
 * Почем здесь не используется компонент Checkboxes, спросишь ты?
 * А потому что в IE не получилось добиться его корректной работы
 * внутри селекта
 */
const specialClassName = 'select-option '

const Option: FC = (props: any) => {
  return (
    <components.Option {...props}>
      {/* TODO Нифига так не пашет  */}
      {/* <Checkbox checked={props.isSelected} onChange={() => null}>
        {props.label}
      </Checkbox> */}
      <div className={clsx(specialClassName, styles.option)}>
        <label className={styles.label} onChange={() => null}>
          <input
            className={styles.checkbox}
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />
          <span className={styles.checkmark}></span>

          {props.label}
        </label>
      </div>
    </components.Option>
  )
}
const MultiValue: FC = () => <div className={styles.item} />

const NoOptionsMessage: FC = () => (
  <div>
    <div className={styles.empty}>Отсутствует</div>
  </div>
)

const MenuList: FC = ({ children, ...props }: any) => (
  <components.MenuList {...props}>
    <div className={styles.scrollContainer}>
      <ScrollArea modificator={styles.scrollAreaChat}>{children}</ScrollArea>
    </div>
  </components.MenuList>
)

const DropdownIndicator: FC = () => (
  // <components.DropdownIndicator {...props}></components.DropdownIndicator>
  <></>
)

export { Option, MultiValue, NoOptionsMessage, MenuList, DropdownIndicator }

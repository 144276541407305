import { STATIC_URL } from 'constants/data'

const preloadIcon = `${STATIC_URL}/preload.svg`
const preloadIconWhite = `${STATIC_URL}/preload_white.svg`

interface ILoader {
  text?: string
  modificator?: string
  theme?: LoaderThemes
}

enum LoaderThemes {
  WHITE = 'white',
  BLUE = 'blue',
}

const preloadIcons = {
  [LoaderThemes.BLUE]: preloadIcon,
  [LoaderThemes.WHITE]: preloadIconWhite,
}

export type { ILoader }
export { preloadIcons, LoaderThemes }

import { useContext } from 'react'
import { useRouter } from 'next/router'

import SEOContext from 'context/SEOContext'

import { scenario, scenarioName } from 'constants/onboarding'
import { PLACEFLAG_FUTURE } from 'constants/analytic-data'
import { Pathname } from 'constants/data'

const useOnboardingCreateConfig = () => {
  const seo = useContext(SEOContext)
  const { pathname } = useRouter()

  const withoutAlert = seo?.PLACE === PLACEFLAG_FUTURE

  let config: Partial<typeof scenario> = {
    [scenarioName.MAIN_PAGE_SCENARIO]: scenario[scenarioName.MAIN_PAGE_SCENARIO],
    [scenarioName.ACTIVE_WEBINAR_SCENARIO]: scenario[scenarioName.ACTIVE_WEBINAR_SCENARIO],
  }

  if (seo?.PLACE === PLACEFLAG_FUTURE || pathname === `/${Pathname.WEBINAR}`) {
    config = {
      ...config,
      [scenarioName.FUTURE_WEBINAR_SCENARIO]: scenario[scenarioName.FUTURE_WEBINAR_SCENARIO],
    }
  }

  return { config, withoutAlert }
}

export default useOnboardingCreateConfig

import { FC } from 'react'
import clsx from 'clsx'

import SelectMulti from '../select-multi/select-multi'

import { IOptions } from 'constants/types/common.types'
import MultipleSelectType from 'constants/multiple-select-variants'

import styles from './form-select-multi-react.module.scss'

interface IFormSpecsMulti {
  title: string
  options: IOptions[]
  placeholder: string
  values: IOptions[]
  setValues: (selected: IOptions[]) => void
  nameInput: string
  error?: any // ПОДУМАТЬ
  tabIndex?: number
  blurSelect?: () => void
  touched?: boolean
  onFocus?: () => void
  customStyles?: any
  labelModificator?: string
  inputNameModificator?: string
  innerContainerModificator?: string
  multipleBoxModificator?: string
  innerBoxModificator?: string
  inputWrapperModificator?: string
  btnModificator?: string
  modificator?: string
  sendGA?: boolean
}

const FormSpecsMulti: FC<IFormSpecsMulti> = ({
  title,
  options,
  placeholder,
  values,
  setValues,
  nameInput,
  error,
  tabIndex,
  blurSelect,
  sendGA,
  onFocus,
  customStyles,
  inputNameModificator,
  multipleBoxModificator,
  innerBoxModificator,
  inputWrapperModificator,
  btnModificator,
  modificator = MultipleSelectType.userSpecialities,
}: IFormSpecsMulti) => {
  return (
    <div
      className={clsx(styles.innerBox, { [innerBoxModificator as string]: innerBoxModificator })}
    >
      <div
        className={clsx({
          [multipleBoxModificator as string]: multipleBoxModificator,
        })}
      >
        {nameInput && (
          <p
            className={clsx(styles.outerName, {
              [inputNameModificator as string]: inputNameModificator,
            })}
          >
            {nameInput}
          </p>
        )}

        <div
          className={clsx(styles.widthLimiter, {
            [inputWrapperModificator as string]: inputWrapperModificator,
          })}
        >
          <SelectMulti
            title={title}
            values={values}
            setValues={setValues}
            placeholder={placeholder}
            options={options}
            modificator={modificator}
            btnModificator={btnModificator}
            blurSelect={blurSelect}
            tabIndex={tabIndex}
            sendGA={sendGA}
            onClick={() => {
              onFocus?.()
            }}
            customStyles={customStyles}
          />
          {error && (
            <div>
              <p className={styles.errorCheck}>{error?.message}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default FormSpecsMulti

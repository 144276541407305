import { MINUTE } from 'constants/timeslot'

const monthTranslation = {
  январь: 'января',
  февраль: 'февраля',
  март: 'марта',
  апрель: 'апреля',
  май: 'мая',
  июнь: 'июня',
  июль: 'июля',
  август: 'августа',
  сентябрь: 'сентября',
  октябрь: 'октября',
  ноябрь: 'ноября',
  декабрь: 'декабря',
}

const weekDays = ['вск', 'пнд', 'втр', 'срд', 'чтв', 'птн', 'суб']

const formatDay = (date: Date): string => ('00' + date.getDate()).slice(-2)
const formatMonth = (date: Date): string => ('00' + (date.getMonth() + 1)).slice(-2)
const formatYearLastNumbers = (date: Date): string => String(date.getFullYear()).slice(-2)
const formatHours = (date: Date): string => ('00' + date.getHours()).slice(-2)
const formatMinutes = (date: Date): string => ('00' + date.getMinutes()).slice(-2)

const generateMonthName = (date: Date): string => {
  const month = date.toLocaleDateString('ru', { month: 'long' })
  const formattedMonth = month.toString().toLowerCase().replace('\u200E', '')
  return monthTranslation[formattedMonth as keyof typeof monthTranslation]
}

const generateShortMonthName = (date: Date): string => {
  const monthName = generateMonthName(date)
  return monthName ? monthName?.slice(0, 3) : ''
}

const generateWeekendName = (date: Date): string => {
  const weekend = date.toLocaleDateString('ru', { weekday: 'short' })
  const formattedWeekend = weekend.toString().toLowerCase().replace('\u200E', '')
  return formattedWeekend
}

const generateShortWeekDayName = (date: Date): string => {
  return weekDays[date.getDay()]
}

const generateTime = (date: Date): string => `${formatHours(date)}:${formatMinutes(date)}`
const generateStringShortDate = (date: Date) => `${date.getDate()} ${generateMonthName(date)}`

/* TODO подумать как унифицировать эти две функции */
/* Такой формат: 2Ч 32МИН */
const generateStringTimeFromSec = (durationInSec: number | undefined): string => {
  let sec = Number(durationInSec)
  const output = []
  if (sec) {
    if (sec && sec > 3599) {
      output.push(`${parseInt(String(sec / 3600))} ч`)
      sec %= 3600
    }
    if (sec && sec > 0) {
      output.push(`${parseInt(String(sec / 60))} мин`)
    }
  }
  return output.join(' ')
}

/* Такой формат: 2:32:10 */
const generateDateTimeFromSec = (durationInSec: number | undefined): string => {
  let sec = Number(durationInSec)
  const output = []
  if (sec) {
    if (sec && sec > 3599) {
      output.push(parseInt(String(sec / 3600)))
      sec %= 3600
    }
    output.push(('0' + parseInt(String(sec / 60))).slice(-2))
    output.push(('0' + (sec % 60)).slice(-2))
  }
  return output.join(':')
}

// Из миллисекунд в формат 13.13.23

const valueDate = (startDate: number) => {
  const dateML = new Date(startDate)

  let day = dateML.getDate()
  let month = dateML.getMonth() + 1

  let formatDay = day <= 9 ? `0${day}` : day
  let formatMonth = month <= 9 ? `0${month}` : month

  return `${formatDay}.${formatMonth}.${dateML.getFullYear() - 2000}`
}

const formatDate = (date: number | string | Date, isFullYear?: boolean): string => {
  if (!date) return ''
  const formatedDate = Number(date) ? new Date(Number(date)) : new Date(date)
  const day = formatDay(formatedDate)
  const month = formatMonth(formatedDate)
  const yearLastNumbers = isFullYear
    ? formatedDate.getFullYear()
    : formatYearLastNumbers(formatedDate)
  return `${day}.${month}.${yearLastNumbers}`
}

function addMinutes(date: Date, minutes: number) {
  return new Date(date.getTime() + minutes * MINUTE)
}

function addDays(date: Date, days: number) {
  return new Date(date.setDate(date.getDate() + days))
}

function removeMinutes(date: Date, minutes: number) {
  return new Date(date.getTime() - minutes * MINUTE)
}

const formatMoscowDate = (date: Date | string) => {
  const formatedDate = Number(date) ? Number(date) : new Date(date as Date)?.getTime()
  if (formatedDate) {
    const userOffset = new Date().getTimezoneOffset() * MINUTE
    const moscowOffset = 180 * MINUTE
    const localizedDate = new Date(formatedDate + userOffset + moscowOffset)

    if (localizedDate) {
      return {
        monthName: generateMonthName(localizedDate),
        weekDayName: generateWeekendName(localizedDate),
        time: generateTime(localizedDate),
        date: localizedDate.getDate(),
      }
    }
  }
}

const dateIsPast = (moscowDate: Date, userDate = new Date()) => {
  const userOffset = new Date().getTimezoneOffset() * MINUTE
  const moscowOffset = 180 * MINUTE
  const moscowDateUTC = Number(moscowDate) - moscowOffset
  const userDateUTC = Number(userDate) + userOffset

  return moscowDateUTC <= userDateUTC
}

const dateIsFuture = (moscowDate: Date, userDate = new Date()) => {
  return !dateIsPast(moscowDate, userDate)
}

const dateIsBetween = (start: Date, end: Date, userDate = new Date()) => {
  const startIsPast = dateIsPast(start, userDate)
  const endIsFuture = dateIsFuture(end, userDate)

  return startIsPast && endIsFuture
}

export {
  formatMoscowDate,
  addMinutes,
  addDays,
  removeMinutes,
  formatHours,
  formatMinutes,
  generateMonthName,
  generateWeekendName,
  generateTime,
  generateStringShortDate,
  formatDay,
  formatMonth,
  formatYearLastNumbers,
  generateStringTimeFromSec,
  generateDateTimeFromSec,
  generateShortMonthName,
  formatDate,
  generateShortWeekDayName,
  dateIsPast,
  dateIsFuture,
  dateIsBetween,
  valueDate,
}

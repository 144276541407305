import { useEffect } from 'react'

const usePopupScrollPosition = (isActive: boolean) => {
  const showDialog = () => {
    const scrollY = document.documentElement.style.getPropertyValue('--scroll-y')
    document.body.style.position = 'fixed'
    document.body.style.overflowY = 'scroll'
    document.documentElement.style.height = '100vh'
    document.documentElement.style.minHeight = '100vh'
    document.body.style.top = `-${scrollY}`
  }
  const closeDialog = () => {
    const scrollY = document.body.style.top
    document.body.style.position = ''
    document.body.style.overflowY = ''
    document.body.style.top = ''
    document.documentElement.style.height = 'initial'
    window.scrollTo(0, parseInt(scrollY || '0') * -1)
  }

  useEffect(() => {
    if (isActive) {
      showDialog()
      return () => closeDialog()
    }
  }, [isActive])
}

export default usePopupScrollPosition

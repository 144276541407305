import { FC } from 'react'
import clsx from 'clsx'
import Image from 'next/image'

import { ILoader, LoaderThemes, preloadIcons } from './data'

import styles from './loader.module.scss'

const Loader: FC<ILoader> = ({ text, modificator, theme = LoaderThemes.BLUE }: ILoader) => {
  const iconSrc = preloadIcons[theme]

  return (
    <div className={styles.loadMessage}>
      <Image
        src={iconSrc}
        width={50}
        height={50}
        className={clsx({ [modificator as string]: modificator })}
        alt="загрузка"
      />
      {text && <p className={styles.text}>{text}</p>}
    </div>
  )
}

//TODO: разобраться с расположением текста

export default Loader

import { useState, FC } from 'react'
import dynamic from 'next/dynamic'

import AuthPopupWrapper from '../auth-popup-wrapper/auth-popup-wrapper'
const RegistrationOlympSteps = dynamic(
  () => import('./registration-olymp-steps/registration-olymp-steps')
)
import StepsForm from './steps-form/steps-form'

import { stepName } from './registration-olymp-steps/data'
interface IRegistrationOlympPopup {
  handleClose: () => void
}

const RegistrationOlympPopup: FC<IRegistrationOlympPopup> = ({ handleClose }) => {
  const [currentStep, setCurrentStep] = useState(stepName.step1)
  const [progressCurrentStep, setProgressCurrentStep] = useState(0)

  const setProgressFORM = (values: any, errors: any) => {
    if (values) {
      const valuesArr = Object.keys(values)
      const errorsArr = Object.keys(errors)

      const pointsForStep = 100 / valuesArr.length

      const progress = valuesArr.reduce((acc: number, item: any) => {
        if (values[item] && values[item]?.length !== 0 && !errorsArr.includes(item)) {
          return acc + pointsForStep
        }
        return acc
      }, 0)

      setProgressCurrentStep(progress)
    }
  }

  return (
    <AuthPopupWrapper>
      <StepsForm
        currentStep={currentStep}
        steps={[stepName.step1, stepName.step2]}
        progressCurrentStep={progressCurrentStep}
        noNumbering
      />
      <RegistrationOlympSteps
        setProgressStep={setProgressFORM}
        setCurrentStep={setCurrentStep}
        currentStep={currentStep}
        handleClose={handleClose}
      />
    </AuthPopupWrapper>
  )
}

export default RegistrationOlympPopup

import { components } from 'react-select'

import {
  Option,
  MultiValue,
  NoOptionsMessage,
  MenuList,
  DropdownIndicator,
} from './select-multi.parts'
import numericToWord from 'utils/numericToWord'
import MultipleSelectType from 'constants/multiple-select-variants'

import styles from './select-multi.module.scss'

const SelectParts = { Option, MultiValue, NoOptionsMessage, MenuList, DropdownIndicator }
const SPECIALITY_WORD_FORM = ['специальность', 'специальности', 'специальностей']
const THEME_WORD_FORM = ['тема', 'темы', 'тем']

const getParts = (modificator: string, length: number) => {
  const isFilters = modificator === MultipleSelectType.filter
  const isUserSpecs = modificator === MultipleSelectType.userSpecialities
  const isSpecSubs = modificator === MultipleSelectType.themes

  if (isFilters) return { ...SelectParts }

  if (isUserSpecs || isSpecSubs) {
    const wordForms = isUserSpecs ? SPECIALITY_WORD_FORM : THEME_WORD_FORM
    const text = numericToWord(length, wordForms)
    const Placeholder = ({ children, ...props }: any) => (
      <components.Placeholder {...props} className={styles.placeholder}>
        {length ? `${length} ${text}` : children}
      </components.Placeholder>
    )
    return {
      ...SelectParts,
      Placeholder,
    }
  }
}

export { getParts }

import { FC } from 'react'
import clsx from 'clsx'

import styles from './select-arrow.module.scss'

interface ISelectArrow {
  isActive?: boolean
}

const SelectArrow: FC<ISelectArrow> = ({ isActive }) => {
  return (
    <div className={clsx(styles.wrapper, { [styles.active]: isActive })}>
      <div className={styles.arrow}></div>
    </div>
  )
}

export default SelectArrow

import { FC } from 'react'
import clsx from 'clsx'

import { stepColors } from '../data'

import styles from './steps-form.module.scss'

interface IStepForm {
  currentStep: string
  progressCurrentStep: number
  steps: string[]
  noNumbering?: boolean
}

const StepsForm: FC<IStepForm> = ({ currentStep, progressCurrentStep, steps, noNumbering }) => {
  return (
    <div className={styles.steps}>
      {steps.map((step, index) => {
        const numStep = index + 1
        const isActive = index <= steps.indexOf(currentStep)

        return (
          <div key={step} className={styles.step}>
            <p
              className={clsx(styles.stepName, {
                [styles.active]: isActive,
              })}
            >
              {noNumbering ? `${step}` : `${numStep}. ${step}`}
            </p>
            <div className={styles.line}>
              <div
                className={clsx(styles.line, styles.lineActive)}
                style={
                  isActive
                    ? {
                        background: `linear-gradient(90deg, ${stepColors[index]} 0%, ${stepColors[numStep]} 100%)`,
                        width: step === currentStep ? `${progressCurrentStep}%` : '100%',
                      }
                    : {}
                }
              />
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default StepsForm

import { FC, useState, useEffect, useRef } from 'react'
import intlTelInput from 'intl-tel-input'

import Auth from 'store/auth'
import InputValidation from 'components/form-parts/input-validation-react/input-validation-react'

import { preferredCountries } from 'constants/auth/profile-data'
import { FieldsNameVoc } from 'constants/auth/profile-data'

import 'intl-tel-input/build/css/intlTelInput.css'

interface IFormPhone extends React.InputHTMLAttributes<HTMLInputElement> {
  error: any
  inputRef: any
  fieldKey?: string
  codeKey?: string
  value: string
  setValue: (inputName: string, value: string) => void
  setCountry: any
  modificator?: string
  labelModificator?: string
  inputNameModificator?: string
  innerContainerModificator?: string
  inputWrapperModificator?: string
  errorModificator?: string
  dynamicName?: boolean
  noName?: boolean
  initInput?: () => void
}

const FormPhone: FC<IFormPhone> = ({
  error,
  inputRef,
  fieldKey,
  codeKey,
  setValue,
  setCountry,
  value,
  modificator,
  labelModificator,
  inputNameModificator,
  innerContainerModificator,
  inputWrapperModificator,
  noName,
  ...props
}: IFormPhone) => {
  const [mask, setMask] = useState('')

  const intlTelInputRef = useRef<any>(null)
  const phoneNumberRef = useRef<HTMLInputElement | null>(null)
  const { isAuthorized, userProfile } = Auth.useAuth()

  const setInputRef = (element: HTMLInputElement) => {
    if (phoneNumberRef) {
      phoneNumberRef.current = element
      inputRef.current = element
    }
  }

  const checkPlus = (valueInput: string) => {
    if (!valueInput) return
    if (valueInput?.includes('+')) {
      return valueInput
    }
    return `+${valueInput}`
  }

  useEffect(() => {
    if (phoneNumberRef.current) {
      setTimeout(() => {
        if (phoneNumberRef.current)
          intlTelInputRef.current = intlTelInput(phoneNumberRef.current, {
            initialCountry: isAuthorized ? 'auto' : 'ru',
            separateDialCode: false,
            preferredCountries,
          })
      }, 200)

      phoneNumberRef.current.addEventListener('countrychange', function () {
        const codePhone = intlTelInputRef.current?.selectedCountryData.dialCode

        if (phoneNumberRef.current) {
          if (codePhone && phoneNumberRef.current.value != '+') {
            setValue('phone', `+${codePhone}`)
            setValue(
              FieldsNameVoc.countryCode,
              intlTelInputRef.current?.selectedCountryData.iso2.toUpperCase()
            )
          }
        }
      })
    }
    return () => {
      if (intlTelInputRef.current) intlTelInputRef.current.destroy()
    }
  }, [phoneNumberRef, userProfile.phone])

  const dopProps = {
    ['data-country']: intlTelInputRef.current?.selectedCountryData?.iso2,
  }

  useEffect(() => {
    setCountry(intlTelInputRef.current?.selectedCountryData?.iso2)
    if (intlTelInputRef.current?.selectedCountryData?.iso2 === 'ru') {
      setMask('+7 (999) 999-99-99')
    } else {
      setMask('')
    }
    // что б исправить баг, когда переключаешь страну, курсор на маске, находится  на несколько позиций справа
  }, [intlTelInputRef.current?.selectedCountryData?.iso2])
  return (
    <InputValidation
      nameInput="Телефон"
      name="phone"
      error={error}
      labelModificator={labelModificator}
      inputNameModificator={inputNameModificator}
      innerContainerModificator={innerContainerModificator}
      inputWrapperModificator={inputWrapperModificator}
      modificator={modificator}
      value={checkPlus(value)}
      setInputRef={setInputRef}
      placeholder={`+${intlTelInputRef.current?.selectedCountryData?.dialCode || 7}`}
      noName={noName}
      type="tel"
      mask={mask}
      {...props}
      {...dopProps}
    />
  )
}

export default FormPhone

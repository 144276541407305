import { useContext, useState } from 'react'

import useAlert from 'store/common/useAlert'
import SEOContext from 'context/SEOContext'
import Auth from 'store/auth'
import useOnboardingCreateConfig from './useOnboardingCreateConfig'

import A from 'utils/metrics/analytic'
import prepareSpecs from 'utils/profile/prepareSpecs'
import { GTAGSignUp } from 'utils/metrics/analyticMethods'
import { getRequest, postAxiosSingle, setToken } from 'pages/api/axios'
import { SEOType } from 'constants/SEO'
import { trimFields } from 'utils/trimFields'
import {
  A_SUBSCRIBE_WEBINAR,
  PLACEFLAG_BACKEND,
  A_REGISTRATION_SUCCESS,
} from 'constants/analytic-data'
import { specialFields, DUPLICATE_ERROR, UNKNOWN_ERROR } from 'constants/auth/profile-data'
import { AUTH_URL } from 'constants/data'
import { profileURL } from 'constants/url'
import { LocalStorageKeys, SessionStorageKeys } from 'constants/storage'
import { AlertType } from 'constants/alert'
import { HOUR } from 'constants/timeslot'

const useRegistration = () => {
  const [inUse, setInUse] = useState(false)
  const [error, setError] = useState('')

  const { setAuth } = Auth.useHandleAuth()
  const { setSingleAlert } = useAlert()
  const { config, withoutAlert } = useOnboardingCreateConfig()
  const SEOData = useContext(SEOContext)

  const completeRegistration = () => {
    A.setEvent(A_REGISTRATION_SUCCESS, {
      exp2: process.env.VERSION_TYPE as string,
      subSource: SEOData[SEOType.PLACE] || '',
    })
    localStorage.setItem(LocalStorageKeys.PUSH_WEEKLY_CHECK, String(new Date().getTime() + HOUR))
    sessionStorage.setItem(SessionStorageKeys.FIRST_TIME, 'true')
    sessionStorage.setItem(SessionStorageKeys.SUCCESS_REGISTRAION, 'true')
    sessionStorage.setItem(SessionStorageKeys.ONBOARDING_STATUS, JSON.stringify(config))

    window?.scrollTo(0, 0)

    !withoutAlert &&
      setSingleAlert({
        type: AlertType.REGISTRATION_SUCCESS,
        text: 'Благодарим вас за регистрацию на портале Medpoint!',
        id: AlertType.REGISTRATION_SUCCESS,
      })
  }

  const onSubmitRegistration = async (values: any, path?: string) => {
    setInUse(true)
    setError('')
    let code: number | undefined
    try {
      const modifiedValues = {
        ...values,
        path: window.location.href,
      }

      const url = path ? `${AUTH_URL}${path}` : AUTH_URL + '/signup'
      const formatedValues = trimFields(modifiedValues, specialFields)
      const data = await postAxiosSingle(url, {}, formatedValues)

      if (data.status === 200) {
        const { accessToken, expireTime, currentTime, webinarId } = data.data
        setToken(accessToken)
        const currentProfile = await getRequest(profileURL)

        if (webinarId)
          A.setEvent(A_SUBSCRIBE_WEBINAR, { id: String(webinarId), subSource: PLACEFLAG_BACKEND })

        const specs = prepareSpecs(currentProfile.data.Specialities)
        setAuth({
          userProfile: { ...currentProfile.data, Specialities: specs },
          accessToken,
          expireTime,
          currentTime,
        })
        GTAGSignUp()
        completeRegistration()
        code = 200
      } else if (data?.response?.status === 409) {
        setError(DUPLICATE_ERROR)
        code = 409
      } else {
        setError(UNKNOWN_ERROR)
      }
    } catch (err) {
      console.log(err)
      setError(UNKNOWN_ERROR)
    }
    setInUse(false)
    return code
  }

  return { onSubmitRegistration, inUse, error }
}

export default useRegistration

import { FC, useEffect, KeyboardEvent } from 'react'
import { useForm, useFormState } from 'react-hook-form'
import { useRouter } from 'next/router'
import Link from 'next/link'
import clsx from 'clsx'

import Button from 'components/parts/button/button'
import Loader from 'components/parts/loader/loader'
import ControllerBaseInput from 'components/form-parts/controller-hook-forms/controller-base-input/controller-base-input'
import ControllerPhoneInput from 'components/form-parts/controller-hook-forms/controller-phone-input/controller-phone-input'
import ControllerPasswordInput from 'components/form-parts/controller-hook-forms/controller-password-input/controller-password-input'
import ControllerSpecialitiesInput from 'components/form-parts/controller-hook-forms/controller-specialities-input/controller-specialities-input'
import Consent from './consent'
import useRegistration from 'hooks/useRegistration'

import A from 'utils/metrics/analytic'
import { cutHashFromURL } from 'utils/getURLData'
import { SessionStorageKeys } from 'constants/storage'
import { A_REGISTRATION_CLICK_FORM_FIELD, A_AUTH_LOGIN_ERRORS } from 'constants/analytic-data'
import { studentsRegText } from 'components/students/students-data'
import { popupName } from 'constants/auth/auth-data'
import { Pathname } from 'constants/data'
import { IRegistrationStep1, InputPCStyles, InputSpecialitiesStyles, passwordProps } from './data'

import {
  FieldsNameVoc,
  SAVING_MESSAGE,
  emailValidation,
  passwordValidation,
  specialitiesValidation,
} from 'constants/auth/profile-data'

import defaultStyles from './registration-step1.module.scss'

const RegistrationStep1: FC<IRegistrationStep1> = ({
  isButtonHidden,
  setProgressStep,
  isStudentsReg,
  customStyles,
  buttonTheme,
  consentModificator,
  isLoginHidden = false,
  handleLoginButton,
  onSubmitEnd,
  btnText = 'Зарегистрироваться',
}) => {
  const router = useRouter()
  const path = cutHashFromURL(router.asPath)

  const { onSubmitRegistration, inUse } = useRegistration()

  const { handleSubmit, control, getValues, setValue, setError, clearErrors, watch } = useForm({
    mode: 'onBlur',
    defaultValues: {
      [FieldsNameVoc.emailNoAutoComplete]: '',
      [FieldsNameVoc.phone]: '+7',
      [FieldsNameVoc.Specialities]: [],
      [FieldsNameVoc.passwordNoAutoComplete]: '',
      [FieldsNameVoc.countryCode]: 'RU',
    },
  })

  const { errors } = useFormState({ control })

  watch([
    FieldsNameVoc.emailNoAutoComplete,
    FieldsNameVoc.phone,
    FieldsNameVoc.passwordNoAutoComplete,
  ])

  const handleClickInput = (name: string) => {
    setClickEvent(name)
  }
  const handleKeyPress = (e: KeyboardEvent<HTMLFormElement>) => {
    if (e.key === 'Enter') {
      e.stopPropagation()
      e.preventDefault()
      // onSubmitForm(getValues())
    }
  }

  const setClickEvent = (name: string) => {
    let subSource = ''
    switch (router.pathname) {
      case '/': {
        subSource = 'main'
        break
      }
      case `/${Pathname.NMO_COURSE_CARDIO}`: {
        subSource = 'cardio_course'
        break
      }
    }
    const data = { title_field: name, subSource }

    A.setEvent(A_REGISTRATION_CLICK_FORM_FIELD, data)
  }

  const redirectAfterReg = () => {
    if (router.pathname === '/medpulse') {
      router.push(`/${Pathname.CLINICAL_CASE}?type=all`)
    }
  }

  const onSubmitForm = async (data: any) => {
    const { emailNoAutoComplete, passwordNoAutoComplete, ...rest } = data
    const webinarFormat = sessionStorage.getItem(SessionStorageKeys.WEBINAR_FORMAT)
    const formatedData = {
      ...rest,
      email: emailNoAutoComplete,
      password: passwordNoAutoComplete,
    }

    if (webinarFormat) {
      //Для подписки на нужный формат в гибридных вебинарах, умнее и быстрее ничего не придумал
      formatedData.webinarFormat = webinarFormat.toLocaleLowerCase()
    }

    try {
      const code: number | undefined = await onSubmitRegistration(formatedData)

      if (code === 200) {
        onSubmitEnd?.()
        redirectAfterReg()
      }

      if (code === 409) {
        A.setEvent(A_AUTH_LOGIN_ERRORS, { type: 'exsisting_email' })
        setError(FieldsNameVoc.emailNoAutoComplete, {
          type: 'custom',
          message: 'Пользователь с такой почтой уже зарегистрирован',
        })
      }
    } catch {
      console.log('send error')
    }
  }

  const isButtonDisabled = Boolean(inUse || Object.keys(errors).length)
  const loginHash = isStudentsReg ? popupName.loginStudents : popupName.login
  const isLoginShown = isStudentsReg || !isLoginHidden

  const styles = customStyles || defaultStyles

  useEffect(() => {
    if (setProgressStep) {
      setProgressStep(getValues(), errors)
    }
  }, [getValues()])

  return (
    <div className={styles.wrapper}>
      <div className={styles.registration}>
        <form
          onSubmit={handleSubmit(onSubmitForm)}
          onKeyDown={handleKeyPress}
          className={styles.form}
          autoComplete="off"
        >
          <div className={styles.inputWrap}>
            <ControllerSpecialitiesInput
              control={control}
              rules={specialitiesValidation}
              errors={errors}
              sendGA
              nameStar
              nameInput="Специальность"
              {...InputSpecialitiesStyles}
            />
          </div>
          <div
            className={styles.inputWrap}
            onClick={() => {
              handleClickInput(FieldsNameVoc.email)
              clearErrors(FieldsNameVoc.emailNoAutoComplete)
            }}
          >
            <ControllerBaseInput
              control={control}
              name={FieldsNameVoc.emailNoAutoComplete}
              errors={errors}
              nameStar
              rules={emailValidation}
              {...InputPCStyles}
            />
          </div>

          <div
            className={styles.inputWrap}
            onClick={() => {
              handleClickInput(FieldsNameVoc.phone)
              clearErrors(FieldsNameVoc.phone)
            }}
          >
            <ControllerPhoneInput
              control={control}
              errors={errors}
              setValue={setValue}
              nameStar
              {...InputPCStyles}
            />
          </div>

          <div
            className={styles.inputWrap}
            onClick={() => {
              handleClickInput(FieldsNameVoc.password)
              clearErrors(FieldsNameVoc.passwordNoAutoComplete)
            }}
          >
            <ControllerPasswordInput
              control={control}
              name={FieldsNameVoc.passwordNoAutoComplete}
              errors={errors}
              rules={passwordValidation}
              nameStar
              {...passwordProps}
            />
          </div>
          {inUse && (
            <div className={styles.loaderWrapper}>
              <Loader text={SAVING_MESSAGE} />
            </div>
          )}
          {isLoginShown && !inUse && (
            <div className={styles.login}>
              {isStudentsReg && (
                <p
                  className={styles.studentsCaption}
                  dangerouslySetInnerHTML={{ __html: studentsRegText }}
                />
              )}
              <div className={styles.loginContainer}>
                <div className={styles.loginTitle}>
                  <p>Уже зарегистрированы?</p>
                </div>
                {handleLoginButton ? (
                  <button className={styles.link} onClick={handleLoginButton}>
                    Войти в аккаунт
                  </button>
                ) : (
                  <Link className={styles.link} href={`${path}#${loginHash}`}>
                    Войти в аккаунт
                  </Link>
                )}
              </div>
            </div>
          )}

          {!isButtonHidden && !inUse && (
            <div>
              <div>
                <div className={styles.endWrapper}>
                  <div className={styles.button}>
                    <Button type="submit" disabled={isButtonDisabled} theme={buttonTheme}>
                      {btnText}
                    </Button>
                  </div>
                </div>
              </div>
              {/* {registartionError && <p className={styles.errorResponse}>{registartionError}</p>} */}

              <Consent
                consentModificator={clsx(styles.consent, consentModificator)}
                linkModificator={styles.consentLink}
              />
            </div>
          )}
        </form>
      </div>
    </div>
  )
}

export default RegistrationStep1

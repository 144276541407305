import { FC, ReactElement } from 'react'
import clsx from 'clsx'

import ButtonThemes, { secondaryMods, thirdMods } from './themes'

import styles from './button.module.scss'

interface IButton extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  theme?: ButtonThemes
  modificator?: string
  children: string | ReactElement
}

const Button: FC<IButton> = ({ theme, modificator, children, ...props }: IButton) => (
  <button
    className={clsx(styles.button, {
      [styles.buttonSecondary]: theme && secondaryMods.includes(theme),
      [styles.buttonThird]: theme && thirdMods.includes(theme),
      [styles.buttonFourth]: theme === ButtonThemes.FOURTH,
      [styles.buttonFifth]: theme === ButtonThemes.FIFTH,
      [styles.buttonBlack]: theme === ButtonThemes.BLACK,
      [styles.buttonBlackSecond]: theme === ButtonThemes.BLACK_SECOND,
      [styles.buttonGrey]: theme === ButtonThemes.GREY,
      [styles.buttonWhite]: theme === ButtonThemes.WHITE,
      [styles.buttonPurple]: theme === ButtonThemes.PURPLE,
      [styles.buttonCongress]: theme === ButtonThemes.CONGRESS,
      [styles.buttonStudents]: theme === ButtonThemes.STUDENTS,
      [styles.buttonStudentsBlue]: theme === ButtonThemes.STUDENTS_BLUE,
      [styles.buttonStudentsNoHover]: theme === ButtonThemes.STUDENTS_NO_HOVER,
      [styles.buttonStudentsSecondary]: theme === ButtonThemes.STUDENTS_SECONDARY,
      [styles.buttonWhiteNoHover]: theme === ButtonThemes.WHITE_NO_HOVER,
      [styles.buttonYellow]: theme === ButtonThemes.YELLOW,
      [modificator as string]: modificator,
    })}
    {...props}
  >
    {children}
  </button>
)
export default Button
